.NavbarCont {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.00) 100%), #FEFEFE;
    position: relative;
    width: 100%;
    z-index: 1000;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1111;
    transition: 0.1s linear !important;
}

.NavbarCont * {
    transition: 0.1s linear !important;
}

.navBarHightChanger {
    width: 100%;
    height: 5vh !important;
    transition: 0.1s linear;
    background: rgba(41, 49, 85, 0.358) !important;
    backdrop-filter: blur(10px);
    overflow: hidden;
}

.navBarHightChanger p {
    font-size: 12px;
    transition: 0.1s linear;

}

.navBarHightChanger .logoSection a img {
    width: 5.5rem;
    height: 1.5rem !important;
    margin-top: 3px !important;
    transition: 0.1s linear;
}

.NavbarCont .navRapper {
    width: var(--Nav-width);
    margin: auto;
    height: 100%;
    /* max-width: 1400px; */
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.NavbarCont .listWrapper2 {
    right: -100%;
    transition: 1s;
}

.NavbarCont .logoSection a {
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    /* padding: 0 0 0 50px; */
}

.NavbarCont .logoSection p {
    color: #000;
    font-family: Inter;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.2px;
}

.getintouch {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-left: 1rem;
    font-size: 18px;
    text-decoration: none;
    color: white;
    font-weight: 400;
    border-left: 2px solid white;
}

.getintouchCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.NavbarCont .listWrapper ul li a:last-child {
    gap: 0.5rem;
}

.getintouch img {
    filter: invert(1);
    transition: 0.5s;
    border-radius: 51%;
    position: relative;
    width: 1.6rem;
    height: 1.6rem;
}


.getintouch:hover img {
    transform: rotate(45deg);
    transition: 0.5s;
    background-color: yellow;

}










@media (max-width:1190px) {
    .NavbarCont .logoSection p {
        color: #000;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 18.2px;
    }

}

.NavbarCont .listWrapper {
    display: flex;
    align-items: center;
    transition: 0.7s;

}

.NavbarCont .listWrapper ul {
    display: flex;
    align-items: center;
}

.NavbarCont .listWrapper ul li {
    list-style: none;
    padding: 0px 15px;
}

.NavbarCont .listWrapper ul li a {
    color: #000;
    text-decoration: none;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30.96px;
}

/* ===================================>
nav responsive 
======================================> */

/* ======================>
Nav SideBar Button
=====================> */



.hamber {
    display: none;
    /* border: 2px solid red; */
    height: 2.5rem;
    width: 2.5rem;
    position: relative;
    z-index: 1003;
    border-radius: 50%;
    overflow: hidden;
    outline: none;
    border: none;
    background-color: transparent;
}

.hamber span {
    width: 70%;
    height: 4px;
    position: absolute;
    left: 0;
    margin: auto;
    left: 6px;
    border-radius: 26px;
}

.hamber span:nth-child(1) {
    top: 31%;
    transition: 1s;
    background-color: white;
}

.hamber span:nth-child(2) {
    top: 50%;
    transition: 1s;
    background-color: white;
}

.hamber span:nth-child(3) {
    top: 69%;
    transition: 1s;
    background-color: white;
}

.hamber2 span {
    background-color: #2251FF;
}

.hamber2 span:nth-child(1) {
    background-color: #2251FF;
    /* display: ; */
    transform: rotate(45deg);
    top: 18px;
    left: 6px;
    transition: 1s;
}

.hamber2 span:nth-child(2) {
    background-color: #2251FF;
    display: none;
    transition: 1s;
}

.hamber2 span:nth-child(3) {
    background-color: #2251FF;
    top: 18px;
    right: 12px;
    transition: 1s;
    transform: rotate(-45deg);
}

/* ===================>
Back To Top Button
===================> */
.backToTop {
    position: fixed;
    height: 3rem;
    width: 3rem;
    bottom: 1rem;
    right: 1rem;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: 4s ease;
    z-index: 999;

}

.backToTop img {
    height: 1.5rem;
    width: 1.5rem;
}





/* ==============>
Responsive
==============> */
@media (max-width:1250px) {

    .NavbarCont .logoSection a img {
        height: 1.2rem !important;
        width: 5rem !important;
    }

    .NavbarCont .logoSection p {
        font-size: 17px !important;
    }

    .NavbarCont .listWrapper ul li a,
    .homePageSection .headerRapper .homeHeaderContent p,
    .homePageSection .homeServices .homeServicesRapper .homeServiceCard p,
    .homePageSection .homePageCardsSection .homePageCards .cardContentRapper p {
        font-size: 12px;
    }

    .homePageSection .trandingRapper .trandingTitle {
        font-size: 15px;
    }

    .NavbarCont .listWrapper .getintouch {
        border-left: none;
    }

    .NavbarCont .listWrapper .getInTouch img {
        height: 1.3rem;
    }

    .homePageSection .homeHeaderSec .headerRapper .homeHeaderContent h1 {
        font-size: 39px;
    }

    .trandingbar {
        padding: 0;
    }

    .trandingRapper {
        height: 100%;
    }

    .homePageSection .trandingRapper .trandingCards .CardCountNum {
        font-size: 17px;

    }

    .homePageSection .trandingbar .trandingRapper .trandingCardTitle {
        font-size: 13px;
    }

    .homePageSection .homeServices .homeServicesRapper .homeServiceCard img,
    .homePageSection .homePageCardsSection .homePageCards .homePageCard img {
        height: 2rem;
    }

    .blogPageHeader h1 {
        font-size: 40px;
    }

    .blogCardHeader .blogHeaderContent h1 {
        font-size: 37px;
    }

    .blogCardHeader .blogHeaderContent span {
        font-size: 14px;
    }
}



@media (max-width:1024px) {
    .NavbarCont .listWrapper ul li {
        padding: 0 9px;
    }

    .homePageSection .homeHeaderSec .headerRapper .homeHeaderContent h1 {
        font-size: 38px;
    }

    .homePageSection .headerRapper .homeHeaderContent .headerBtnSec a {
        height: 3rem;
        width: 8rem;
        font-size: 12px;
    }

    .listWrapper {
        position: fixed;
        width: 50vw;
        top: 0;
        right: 0;
        height: 100vh;
        background-color: transparent;
        backdrop-filter: blur(108px);
        flex-direction: column;
        right: 0;
        z-index: 800;
        padding-left: 2rem;

    }

    .listWrapper ul {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-top: 5rem;

    }

    .listWrapper ul li {
        width: 100%;
        height: 3rem;
        position: relative;
    }

    .listWrapper ul li a {
        width: 100%;
        height: 100%;
        position: absolute;
    }


    .getInTouch {
        width: 100%;
        padding: 0 9px;
        height: 3rem;
    }


    .hamber {
        display: block;
    }

    .circletimeline {
        top: 33%;
    }

    .DskDisNone {
        display: flex !important;
    }

}

@media (max-width:768px) {
    .homePageSection .headerRapper .homeHeaderContent .headerBtnSec a {
        background-color: var(--header-btn-color) !important;
        color: var(--header-btn-color-txt) !important;
    }

    .homePageSection .homeHeaderSec::after {
        display: none;
    }

    .homePageSection .homeHeroSection {
        height: 50vh;
    }

    .detilSec {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .circletimeline {
        top: 50%;
    }

    .homePageSection .homeHeroSection {
        height: 93vh;
    }

    .homePageSection .homeHeaderSec {
        height: 90%;
    }

    .homePageSection .trandingbar {
        height: 15%;
    }

    .timelineDetailCard {
        position: absolute;
        top: 25%;
        left: 30%;
    }

    .circletimeline .circletimelineDots {
        left: 47vw;
    }

    .getintouch {
        padding: 0;
    }

    .listWrapper ul li a {
        gap: 1rem;
    }

    .listWrapper ul li a img {
        height: 1.5rem;
    }

    .NavbarCont .listWrapper ul li a,
    .getintouchCont {
        width: 70%;
        text-align: start;
        display: flex;
        align-items: center;
        /* justify-content: center; */
        font-size: 23px;
    }

    .HomePageBlog .homeBlogContentSection {
        display: flex !important;
        flex-direction: column !important;
    }

    .homePageSection .trandingRapper .trandingCards .CardCountNum {
        height: 70%;
    }

    .homePageSection .trandingRapper .trandingCards {
        width: 14rem;
    }

    .homePageSection .trandingRapper {
        width: fit-content;
        animation: ScrollMarque 20s infinite linear;

    }

    .trandingTitle {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: fit-content;
        background-color: #2251FF;
        z-index: 1;
        padding: 0 7px;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 3px 0 19px 35px #2251FF;
    }

    .homePageSection .trandingRapper:last-child {
        display: flex;
    }

    .innerTitleTranding {
        display: none !important;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: fit-content;
        background-color: #2251FF;
        z-index: 1;
        padding: 0 7px;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 3px 0 19px 35px #2251FF;
    }

    .homePageSection .homePageCardsSection .homePageCards .homePageCard {
        gap: 14px;
    }

    .FullAnimSec {
        height: fit-content;
    }

    .navBarHightChanger,
    .NavbarCont {
        position: relative;
    }

    .NavbarCont,
    .navBarHightChanger {
        height: 4rem !important;
        background-color: var(--dark-back) !important;
        position: relative !important;
    }

    .NavbarCont .listWrapper .getInTouch img,
    .NavbarCont .logoSection a img {
        width: 8rem;
        height: 2rem;
    }

    .NavbarCont .logoSection p {
        font-size: 17px;
    }

    .homePageSection .headerRapper,
    #workflow .body,
    #workflow .head,
    .homePageSection .homeServices .homeServicesRapper,
    .homePageSection .homePageCardsSection .homePageCardsRapper,
    .blogCardHeader .blogHeaderContent {
        width: 90% !important;
    }

    :root {
        --Nav-width: 90% !important;
    }
}






















/* ===============================>
Responsove max 470px
===============================> */

@media (max-width:470px) {


    .circletimeline {
        top: 60%;
    }

    .homePageSection .homeServices .homeServicesRapper .homeServicesCards,
    .homePageSection .homePageCardsSection .homePageCardsRapper .homePageCards {
        flex-wrap: wrap;
        height: fit-content;
        justify-content: space-between;
        gap: 38px;
    }

    .homePageSection .homeServices .homeServicesRapper .homeServiceCard {
        width: 100%;
    }

    .homePageSection .homePageCardsSection {
        margin-top: 1rem;
    }

    .blogPageHeader h1,
    .blogCardHeader .blogHeaderContent h1 {
        font-size: 21px;
        text-align: center;
    }


    .blogPageHeader a span {
        font-size: 13px;
    }

    .blogCardHeader .blogHeaderContent span {
        font-size: 11px;
    }

    .homePageSection .homePageCardsSection .homePageCards h1 {
        font-size: 16px;
    }

    .homePageSection .homePageCardsSection .homePageCards .cardContentRapper,
    p .contact-body .service-content a,
    .homePageSection .homePageCardsSection .serviceExpore,
    .homePageSection .homeServices .homeServicesRapper .serviceExpore {
        font-size: 10px;
    }

    .homePageCardsSection .serviceExpore img {
        height: 17px;
    }

    .homePageSection .homePageCardsSection .productheading {
        font-size: 21px;
    }


    .homePageSection .trandingRapper .trandingCards {
        width: 14rem;
    }

    .homePageSection .trandingbar {
        height: 10vh;
        overflow: hidden;
        position: relative;

    }

    .timelineDetailCard {
        left: 10%;
        height: 13rem;
    }

    .homePageSection .homeHeaderContent .headerBars {
        display: none;
    }

    .homePageSection .homeHeaderContent {
        width: 100%;
    }

    .homePageSection .homeHeaderSec {
        height: 75vh;
    }

    .homeBlogTitle {
        font-size: 15px;
        padding: 5px;
    }

    .blogCardHomeSection:nth-child(1) .homeBlogContent,
    .secondCardLower,
    .blogCardHomeSection:nth-child(4) .lastblogcardcontents,
    .blogCardHomeSection:nth-child(3) .homeBlogContent,
    .blogCardHomeSection:nth-child(4) .lastblogcardcontents {
        gap: 1rem;
        padding: 5px;
        justify-content: center;

    }


    .blogCardHomeSection:nth-child(1) .homeBlogContent p,
    .homeBlogDate,
    .blogCardHomeSection:nth-child(4) .homeBlogContent .BlogReadMore {
        font-size: 7px;
    }

    .blogCardHomeSection:nth-child(4) .lastblogcardcontents,
    .blogCardHomeSection:nth-child(3) .homeBlogContent {
        padding: 10px;
    }

    .blogCardHomeSection:nth-child(4) .lastblogcardcontents {
        height: 100%;
    }

    .listWrapper {
        width: 100%;
    }

    .NavbarCont .listWrapper ul li {
        /* text-align: start; */
        display: flex;
        justify-content: flex-end;

    }

    .listWrapper {
        padding: 0;
    }

    .getInTouch a {
        text-wrap: nowrap;
    }


    .blogCardHomeSection,
    .blogCardHomeSection:nth-child(3),
    .SecondCardUpper {
        height: 14rem;
    }

    .blogCardHomeSection:first-child .homeBlogContent {
        padding: 11px;
        font-size: 2rem;
    }

    .blogCardHomeSection:first-child .homeBlogContent h1 {
        font-size: 16px;
    }

    .blogCardHomeSection:first-child .homeBlogContent p {
        font-size: 9px;
    }

    .blogCardHomeSection:nth-child(3) .imageRapper {
        width: 50%;
    }

    .homePageSection .trandingRapper {
        flex-direction: row;

    }

}



@media (max-width:360px) {

    .circletimeline {
        top: 70%;
    }

    .timelineDetailCard {
        left: 24%;
        top: 29%;
    }

    .timelineHeadingSec h1 {
        font-size: 15px;
    }
}

@keyframes ScrollMarque {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);

    }
}