/* this css is created for About Page */
.aboutUsPage {
    width: 100%;
    height: fit-content;
    /* background-color: var(--dark-back); */

}

.aboutUsPage .aboutHeader {
    height: 70%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    background: linear-gradient(90deg, #000000c2, transparent);


}

.aboutRapper {
    height: 92.5vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--dark-back);
}

body::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--dark-back);
    z-index: -1;
}

.aboutUsPage .aboutHeader::after {
    content: "";
    position: absolute;
    background: linear-gradient(62deg, black, transparent), var(--aboutUs-header-img);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100vw;
    height: 100%;
    left: -10vw;
    z-index: 1;
}

.aboutUsPage .aboutBodyContent {
    height: fit-content !important;
}

.aboutUsPage .aboutHeader .aboutHeaderContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    margin: auto;
    height: 100%;
    /* padding: 10px; */
    padding-bottom: 40px;
    gap: 0.5rem;
    z-index: 3;
}

.aboutUsPage .aboutHeader .aboutHeaderContent h1 {
    color: var(--dark-text);
    font-size: 52px;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
}

.aboutUsPage .aboutHeader .aboutHeaderContent span {
    color: #FFF;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

.aboutUsPage .aboutBodyContent {
    padding-top: 2.4rem;
}

.aboutUsPage .aboutBodyContent,
.aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutCrdRapper {
    background-color: var(--dark-back) !important;
    width: 100%;
    height: 80vh;

}


.aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutCrdRapper:nth-child(2) {
    flex-direction: row-reverse !important;
}

.aboutUsPage .aboutBodyContent,
.aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutCrdRapper * {
    color: white;
}



.aboutUsPage .aboutBodyContent .aboutBodyCads {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    padding-top: 2rem;

}

.aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.aboutUsPage .aboutBodyContent .aboutBodyCads .howWeWork {
    background-color: var(--about-card-back);
}

.aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutCrdRapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;

}

.whoWeAreRapper:nth-child(2) .aboutCrdRapper {
    flex-direction: row-reverse !important;
}

.aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutCrdRapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;

}

.aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutCrdRapper .aboutCardMainLeft {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
    gap: 1rem;
    font-weight: normal;
    font-family: 'Roboto', sans-serif;
}



.whoWeAreRapper {

    width: 100%;
    margin: auto;
}














.aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutCrdRapper .aboutCardMainLeft p {
    line-height: 27px;
}

.aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutCrdRapper .aboutCardMainRight {
    width: 50%;
    font-family: 'Roboto', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
}

.aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutCrdRapper .aboutCardMainRight img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    object-fit: cover;

}

.aboutExpertise {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 32.6px;
    height: 30%;
    align-items: flex-start;
    background: var(--dark-back);
}

.aboutExpertise * {
    color: white;
}

.aboutExpertise .expartiesCard {
    width: 19%;
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding:2rem 0;

}

.aboutUsPage .aboutExpertise .workCard {
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 17px;
    padding: 10px;
}

.aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutExpertise .exerticesmoreBtn {
    text-decoration: none;
    color: black;
}

/* founder section body style */
.aboutUsPage .founderSection {
    height: 15rem;
    padding-top: 4rem;
    background-color: var(--dark-back);
}

.aboutUsPage .founderSection * {
    font-family: 'Inter', sans-serif;
}

.aboutUsPage .founderSecBody {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #E1E1E1;
}

.aboutUsPage .founderSecBody .founderImage {
    width: 30%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

}

.aboutUsPage .founderSecBody .founderImage img {
    border: 3px solid rgba(128, 128, 128, 0.10);
    border-radius: 50%;
    object-fit: cover;
    width: 155px;
    height: 155px;
}

.aboutUsPage .founderSecBody .founderNameRole {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.aboutUsPage .founderSecBody .founderNameRole h3 {
    color: var(--btn-color-blue);
    font-family: "Montserrat", sans-serif;
}

.aboutUsPage .founderSecBody .founderContent {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
}


.aboutUsPage .develperSection {
    width: 100%;
    height: 75vh;
    margin-top: 3rem;
}

.aboutUsPage .develperSection .developerBody {
    /* border: 2px solid green; */
    width: 70%;
    height: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows:3rem; */
    align-items: center;
    gap: 3rem;
}

.aboutUsPage .develperSection .developerBody .developerProfile {
    /* border: 2px solid yellow; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

}

.aboutUsPage .develperSection .developerBody .developerProfile img {
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
}

.aboutUsPage .develperSection .developerBody .developerProfile h3 {
    color: #3498DB;
    font-weight: normal;
}

/* group photo section */

.groupPhotoSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 6rem 0 3rem 0;
    background-color: var(--dark-back);
}

.groupPhotoSectiongroupPhotoSecBody {
    width: 70%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    justify-content: center;
}

.groupPhotoSection .groupImage {
    width: 100%;
    height: 100vh;
    margin: auto;
}

.groupPhotoSection .familySec {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;

}

.aboutContImg {
    height: 70%;
    width: 100%;
    background: var(--aboutCont-img);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

}

.groupPhotoSection .familyHeading {
    color: var(--btn-color-blue);
    font-family: "Montserrat", sans-serif;

}

.groupPhotoSection p {
    text-align: center;
}


/* ===============>
Responsive
==============> */



@media (max-width:1110px) {
    .aboutUsPage .aboutHeader .aboutHeaderContent h1 {
        font-size: 44px;
    }
}

@media (max-width:1000px) {
    .aboutUsPage .aboutHeader .aboutHeaderContent h1 {
        font-size: 37px;
    }

    .aboutRapper {
        height: fit-content;
    }

    .aboutExpertise {
        width: 100%;
        padding-top: 2rem;
        margin: auto;
        flex-direction: column;
    }

    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutCrdRapper .aboutCardMainLeft h1:nth-child(1),
    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutCrdRapper .aboutCardMainLeft h2:nth-child(1),
    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutExpertise .expartiesCard h4:nth-child(1),
    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutExpertise .workCard h4:nth-child(1),
    .aboutUsPage .founderSecBody .founderNameRole h3,
    .aboutUsPage .develperSection .developerBody .developerProfile h3,
    .groupPhotoSection .familyHeading {
        font-size: 17px;
    }

    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutCrdRapper .aboutCardMainLeft p,
    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutCrdRapper .aboutCardMainLeft p,
    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutExpertise .expartiesCard p,
    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutExpertise .workCard p,
    .aboutUsPage .founderSecBody .founderContent p,
    .aboutUsPage .develperSection .developerBody .developerProfile p,
    .groupPhotoSection p {
        font-size: 13px;
    }

    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutExpertise .exerticesmoreBtn {
        font-size: 10px;
    }

    .aboutUsPage .develperSection .developerBody .developerProfile img,
    .aboutUsPage .founderSecBody .founderImage img {
        height: 7rem;
        width: 7rem;
    }

    .groupPhotoSection .groupImage {
        width: 100%;
        height: 100%;
    }

    .groupPhotoSection p {
        margin-bottom: 1rem;
    }

    .aboutUsPage .founderSecBody {
        gap: 25px;
    }

    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutExpertise {
        gap: 1rem;
    }

    .aboutExpertise .expartiesCard {
        width: 100%;
    }
}



@media (max-width:560px) {
    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutExpertise {
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: auto;
    }

    .aboutExpertise {
        flex-direction: column;
        align-items: flex-start;

    }

    .groupPhotoSectiongroupPhotoSecBody {
        width: 100%;
    }

    .aboutUsPage .aboutHeader {
        height: 35vh;
    }

    /* about us how we work */
    .aboutUsPage .aboutBodyContent .aboutBodyCads .howWeWork {
        padding: 50px 0px 50px 0px;
    }

    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutExpertise .expartiesCard,
    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutExpertise .workCard {
        width: 14rem;
    }
}

@media (max-width:570px) {

    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutCrdRapper .aboutCardMainRight,
    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutCrdRapper .aboutCardMainLeft {
        width: 100%;
        height: 50%;
    }

    .aboutContImg {
        height: 100%;
    }

    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutCrdRapper {
        flex-direction: column-reverse !important;
    }

}

@media (max-width:500px) {
    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutCrdRapper .aboutCardMainRight {
        width: 100%;
    }






    .aboutUsPage .aboutHeader .aboutHeaderContent h1 {
        font-size: 25px;
    }

    .aboutUsPage .founderSecBody .founderContent {
        width: 100%;
        margin: auto;
        align-items: center;
    }

    .aboutExpertise .expartiesCard {
        width: 100%;
    }

    .aboutUsPage .founderSecBody .founderContent p {
        text-align: center;
    }

    .aboutUsPage .develperSection .developerBody {
        width: 90%;
        display: flex !important;
        margin: auto;
        flex-wrap: wrap;
        justify-content: center;
    }

    .aboutUsPage .develperSection .developerBody .developerProfile {
        width: fit-content;
        height: fit-content;
    }

    .aboutUsPage .develperSection {
        height: fit-content;
    }

    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutExpertise .expartiesCard {
        width: 100%;
        margin: auto;
        padding: 0;
    }
}

@media (max-width:450px) {

    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .upperRapper {
        flex-direction: column-reverse;
    }

    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutCrdRapper {
        flex-direction: column;
    }

    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutCrdRapper {
        width: 100%;
        margin: auto;
    }

    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutCrdRapper .aboutCardMainLeft,
    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutCrdRapper .aboutCardMainRight {
        width: 100%;
        margin: auto;

    }

    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutExpertise {
        gap: 1.5rem;
    }

    .aboutUsPage .aboutBodyContent .aboutBodyCads .aboutBodyCardsmain .aboutExpertise .workCard {
        width: 100%;
    }

    .aboutUsPage .founderSection {
        height: fit-content;
    }

    .aboutUsPage .founderSecBody {
        flex-direction: column;
        width: 78%;
        margin: auto;
    }

    .aboutUsPage .founderSecBody .founderImage {
        width: 100%;
        height: 8rem;
    }

    .aboutUsPage .founderSecBody .founderImage img {
        width: 7rem;
        height: 7rem;
    }
}

@media (max-width:602px) {
    .aboutUsPage .aboutHeader::after {
        left: -5vw;
    }
}