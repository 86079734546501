.footerCont {
    padding-top: 77px;
    background: var(--dark-back);
    position: relative;
    z-index: 10;
    color: #FFF;
    position: relative;
}

.footerCont .footerRapper {
    margin: auto;
    width: var(--footer-width);
    height: 100%;
}


.footerCont .infoCont {
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.footerCont .infoCont .info {
    display: flex;
    width: 16%;
    height: 320px;
    /* padding: 40.125px 10.08px 0px 10.08px; */
    padding: 4rem 0 0 0;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
}

.footerCont .infoCont .info p:nth-child(1) {
    padding-bottom: 2px;
    font-size: 18px;
    font-weight: bold;
}

.footerCont .infoCont .info p {
    /* font-family: 'IBM Plex Sans', sans-serif; */
    font-size: 16px;
    font-style: normal;
    font-weight: lighter;
}

.footerCont .infoCont .info a {
    color: #161616;
    /* font-family: 'IBM Plex Sans', sans-serif; */
    font-size: 16px;
    font-style: normal;
    text-decoration: none;
    color: white;
    font-weight: lighter;
}


.footerCont .regionCont {
    display: flex;
    height: 100px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: auto;
}

.footerCont .regionCont .Address_container {
    width: 450px;
}

.footerCont .regionCont .Address_container .address_heading {
    font-weight: bold;
}

.footerCont .regionCont .Address_container .address a{
    font-size: 16px;
    font-style: normal;
    text-decoration: none;
    color: white;
    font-weight: lighter;
}



.footerCont .regionCont .regionWrapper .choseRegion {
    display: flex;
    width: 342px;
    height: 40px;
    flex-shrink: 0;
    border: 2px solid rgba(180, 186, 186, 0.8);
    align-items: center;
    padding-left: 10px;
    margin-top: 10px;
    background: #fff;
    color: #000;
}

.footerCont .regionCont .regionWrapper p {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
}

.footerCont .hrCont {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerCont .hrCont hr {
    background: #AEAEAE;
    width: 100%;
    margin-top: 15px;
}

.footerCont .footerSection {
    display: flex;
    padding-left: 30px;
    justify-content: space-between;
    padding: 0;


}

.footerBottomCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.footerCont .footerSection p,
.footerCont .footerSection a {
    padding: 15px 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}


.footerCont .footerSection .footerBottomCont :nth-child(1){
    padding-left: 0;
}

.footerCont .footerSection p{
    padding-right: 0;
}

.hyperLink {
    text-decoration: none;
    color: white;
}

@media (max-width:940px) {
    .footerCont .infoCont .info p {
        font-size: 14px;
    }

    .footerCont .footerSection p,
    .footerCont .footerSection a {
        padding: 15px 5px;
    }

    .footerCont .regionCont .regionWrapper p {
        font-size: 13px;
    }

    .footerCont .footerSection p,
    .footerCont .footerSection a {
        font-size: 13px;
    }

    .footerCont .regionCont .regionWrapper,
    .footerCont .regionCont .regionWrapper .choseRegion {
        width: 100%;
    }

    .footerCont .footerSection {
        justify-content: space-between;
        margin-bottom: 2rem;
    }

    .footerCont .regionCont {
        margin-top: 3rem;
    }

    .footerCont .footerRapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 3rem;
    }

    .footerCont .infoCont .info {
        height: fit-content;
        width: 100%;
        /* padding: 54.125px 10.08px 0px 10.08px; */
        padding: 4rem 0 0 0;
    }

    .footerCont .infoCont {
        /* flex-wrap: wrap; */
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0.5rem;
    }

    .footerCont .regionCont {
        flex-direction: column;
    }

    .footerCont .regionCont .Address_container {
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 2rem;
    }

    .footerCont .hrCont {
        margin-top: 3rem;
    }
}

@media (max-width:500px) {
    .footerCont .footerSection {
        flex-direction: column;
    }

    .footerCont .footerSection p {
        text-align: center;
    }
}