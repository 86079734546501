@import url('https://fonts.googleapis.com/css2?family=Anton&family=Antonio:wght@600&family=IBM+Plex+Sans:ital,wght@0,500;1,400&family=Inter&family=Roboto:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,500;1,400&family=Inter&family=Montserrat&family=Roboto:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&family=Montserrat:wght@400;500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --light-text: black;
  --dark-text: white;
  --dark-text2: darkblue;
  --light-back: white;
  --dark-back: #161C2D;
  --contact-light: #F0F0F0;
  --light-btn-border: rgb(128, 128, 128, 0.10);
  --dark-btn-border: white;
  --aboutUs-header: #051C2C;
  --about-card-back: #F0F0F0;
  --home-hero-txt: #9898A9;
  --btn-color-blue: #2251FF;
  scroll-behavior: smooth;
}

.d-n {
  display: none;
  transition: 1s;
}

body {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  /* background-color: var(--dark-back); */
  position: relative;
}

body::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--dark-back) !important;
}

/* universal class */

.activeBtn {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
}

.blogSection {
  width: 100%;
  height: 100%;
  background-color: #F0F0F0;
  padding-bottom: 4rem;
}


@media (max-width:600px) {
  .ParentRapper {
    width: 90% !important;
  }
}