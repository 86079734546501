.blogSection,
.blogHero {
    background: var(--dark-back) !important;
}

.heroBody a,
.blogHero .heroBody,
.blogHero .heroBody h1 {
    color: #ffff !important;
}

