.homePageSection {
    width: 100%;
    height: fit-content;
    background-color: #F0F0F0;
}

.homePageSection .homeHeroSection {
    width: 100%;
    height: 92vh;
    position: relative;
}

.homePageSection * {
    font-family: "Montserrat", sans-serif;
}

.homeHeaderSec {
    z-index: 2 !important;
}

.homePageSection .headerRapper {
    width: 80%;
    /* max-width: 1400px;*/
    margin: auto;
    height: 100%;
    overflow: hidden;
}

.homePageSection .headerRapper .homeHeaderContent .headerBtnSec {
    width: 100%;
    display: flex;
    flex-direction: revert;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
}

.homePageSection .homeHeaderSec .headerRapper .homeHeaderContent h1 {
    font-size: 53px;
}

.homePageSection .headerRapper .homeHeaderContent p {
    font-size: 14px;
}

.homePageSection .headerRapper .homeHeaderContent .headerBtnSec a:nth-child(1) {
    height: 3.3rem;
    width: 11rem;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    color: white;
    background-color: black;
}

.homePageSection .headerRapper .homeHeaderContent .headerBtnSec a:nth-child(2) {
    height: 3.3rem;
    background-color: black;
    width: 11rem;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background-color: #2251FF;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    color: white;
}

.homePageSection .homeHeaderContent {
    width: 30rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2.5rem;
}

.homePageSection .homeHeaderContent .headerBars {
    width: 100%;
    height: 6rem;
    background: linear-gradient(#f0f0f0, transparent);
    position: absolute;
}

.homePageSection .homeHeaderContent .headerBarsTop {
    top: 0;
    left: 0;
}

.homePageSection .homeHeaderContent .headerBarsBottom {
    bottom: 0;
    left: 0;
    transform: rotate(180deg);
    /* background:linear-gradient(#2251FF,transparent,transparent,transparent); */
}

.homePageSection .homeHeaderContent .headerBarsRight {
    width: 6rem !important;
    height: 100% !important;
    right: 0;
    top: 0;
    background: linear-gradient(to left, #f0f0f0, transparent) right !important;
    /* border: 2px solid red; */
}

.homePageSection .homeHeaderSec {
    width: 100%;
    height: 90%;
    position: relative;
    overflow: hidden;
}

.homePageSection .homeHeaderSec::after {
    content: "";
    position: absolute;
    background: var(--header-background);
    background-size: cover;
    background-repeat: no-repeat;
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    top: -37px;
    right: -37%;
    z-index: -1;
    background-position: center;
    object-fit: cover;
}

/* ==================================================>;
 trending section 
 =====================================================>; */
.homePageSection .trandingbar {
    /* background-color: #2251FF; */
    background: #2251FF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    height: 12%;
    position: relative;
    overflow: hidden;
}

.trandingTitle {
    display: none;
}

.homePageSection .trandingRapper {
    width: 80%;
    /*max-width: 1400px;*/
    margin: auto;
    display: flex;
    flex-direction: row;
    /* gap: 0.5rem; */
    justify-content: space-between;
}

.homePageSection .trandingRapper:last-child {
    display: none;
}

.homePageSection .trandingRapper .trandingTitle {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.homePageSection .trandingRapper * {
    color: white;
}

.homePageSection .trandingRapper .trandingCards {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 20%;
}

.homePageSection .trandingRapper .trandingCards .CardCountNum {
    width: 2rem;
    height: 100%;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 2px solid white;
}

.homePageSection .trandingbar .trandingRapper .trandingCardTitle {
    width: 80%;
    padding-left: 5px;
    font-size: 12px;
}

.homePageSection .homeServices {
    width: 100%;
    margin-top: 4rem;
    position: relative;
    background-color: var(--dark-back);
}

.serviceCardSection {
    background-color: var(--dark-back);
    position: relative;

}

.homePageSection .homeServices .homeServicesRapper {
    width: 80%;
    /*max-width: 1400px;*/
    display: flex;
    flex-direction: column;
    gap: 5rem;
    margin: auto;
    padding-top: 4rem;
    background-color: var(--dark-back);
}

.homePageSection .homeServices .homeServicesRapper h1 {
    font-weight: normal;
}

.homePageSection .homeServices .homeServicesRapper .serviceExpore {
    font-weight: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    color: #2251FF;
    text-decoration: none;
}

.homePageSection .homeServices .homeServicesRapper .serviceExpore:hover {
    line-height: 5px;
    text-decoration: underline;
}

.homePageSection .homeServices .homeServicesRapper .serviceExpore img {
    height: 17px;
}

.homePageSection .homeServices .homeServicesRapper .homeServicesCards {
    height: 12rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
}

.homePageSection .homeServices .homeServicesRapper .homeServiceCard {
    height: 100%;
    width: 33%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 17px;
}

.homePageSection .homeServices .homeServicesRapper .homeServiceCard h1 {
    font-weight: normal;
    font-size: 20px;
}

.homePageSection .homeServices .homeServicesRapper .homeServiceCard a {
    color: #00AB50;
    text-decoration: none;
    font-weight: 700;
    font-size: 12px;
}

.homePageSection .homeServices .homeServicesRapper .homeServiceCard a:hover {
    color: #00AB50;
    text-decoration: underline;
}

.homePageSection .homeServices .homeServicesRapper .homeServiceCard p {
    width: 80%;
}

.homePageSection .homeServices .homeServicesRapper .homeServiceCard img {
    height: 25px;
}

/* ==================================================> ;
product section style 
=====================================================> ;
*/
.homePageSection .homePageCardsSection {
    gap: 3.5rem;
    margin-top: 8rem;
    width: 100%;
    background-color: var(--dark-back);
    position: relative;
}

.homePageSection .homePageCardsSection .homePageCardsRapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* gap: 3rem; */
    width: 80%;
    /*max-width: 1400px;*/
    margin: auto;
    padding-top: 4rem;
    gap: 5rem;
}

.homePageSection .homePageCardsSection .homePageCardsRapper .homePageCards {
    height: 11rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 3rem;
    width: 100%;
    margin: auto;
}

.homePageSection .homePageCardsSection .productheading {
    font-size: 25px;
    font-weight: normal;
}

.homePageSection .homePageCardsSection .homePageCards {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 37px;
    width: 33%;
}

.homePageSection .homePageCardsSection .homePageCards .cardContentRapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 13px;
    width: 100%;
}

.homePageSection .homePageCardsSection .homePageCards .homePageCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 35px;
    width: 100%;
}

.homePageSection .homePageCardsSection .homePageCards .homePageCard img {
    height: 1.9rem;
    width: 1.9rem;
}

.homePageSection .homePageCardsSection .homePageCards h1 {
    font-weight: normal;
    font-size: 20px;
}

.homePageSection .homePageCardsSection .homePageCards a {
    text-decoration: none;
    color: #00AB50;
}

.homePageSection .homePageCardsSection .homePageCards a:hover {
    text-decoration: underline;
}

.homePageSection .homePageCardsSection .serviceExpore {
    font-weight: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    color: #2251FF;
    text-decoration: none;
}

.homePageSection .homePageCardsSection .serviceExpore:hover {
    text-decoration: underline;
}

/* ==================================================>;
 blog section style 
 =====================================================>; 
 */

.blogPageHeader {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 10rem;
}

.blogPageHeader h1 {
    font-size: 50px;
    font-weight: normal;
}

.blogPageHeader a {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    color: #3e67ff;
}

.blogPageHeader a span {
    font-size: 18px;
}

.blogPageHeader a img {
    height: 20px;
    width: 20px;
}

.blogCardHeader {
    width: 100%;
    margin-top: 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
}

.blogCardHeader .blogHeaderContent {
    width: 80%;
    margin: auto;
    position: absolute;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
}

.blogCardHeader .blogHeaderContent * {
    color: white;
    text-decoration: none;
}

.blogCardHeader .blogHeaderContent h1 {
    font-weight: normal;
    font-size: 51px;
}

.blogCardHeader .blogHeaderContent span {
    font-weight: normal;
    font-size: 16px;
}

.blogCardHeader .blogHeaderCardLeft {
    width: 50%;
    background-color: #2251FF;
    height: 12rem;
}

.blogCardHeader .blogHeaderCardRight {
    width: 50%;
    background-color: #196af6;
    height: 12rem;
    object-fit: cover;
}

/* ==================================================>;
 home blogpage main card section style 
 =====================================================>; */
.HomePageBlog {
    width: 100%;
    height: fit-content;
    margin-top: 0.5rem;
}

.HomePageBlog .newMartian {
    width: 100%;
    text-align: center;
    font-size: 13px;
    color: #fff;
}

.HomePageBlog .homeBlogContentSection {
    /* background-color: #196af6; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 0.5rem;
}

.HomePageBlog .homeBlogContentSection a {
    text-decoration: none;
}

.blogCardHomeSection {
    width: 100%;
    height: 506px;
    position: relative;
    transition: .5s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    cursor: pointer;
}

.blogCardHomeSection:nth-child(1) .blogImage {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    transition: .5s;
}

.blogCardHomeSection:nth-child(1):hover .blogImage {
    position: absolute;
    width: 105%;
    height: 105%;
    top: -5px;
    left: -10px;
    bottom: -5px;
    right: -5px;
    transition: .5s;
}

.blogCardHomeSection:nth-child(1) .homeBlogContent {
    height: 30%;
    width: 100%;
    background-color: red;
    position: absolute;
    z-index: 5;
    bottom: 0;
    left: 0;
    background: linear-gradient(transparent, #eaebf4);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
    padding: 10px;
}

.blogCardHomeSection:nth-child(1) .homeBlogContent p {
    font-size: 12px;
}

.blogCardHomeSection:nth-child(1) .homeBlogContent h1 {
    font-weight: normal;
}

.blogCardHomeSection {
    width: 100%;
}

.SecondCardUpper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    height: 60%;
}

.blogCardHomeSection:nth-child(2) .SecondCardUpper .ImgRapper {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.blogCardHomeSection:nth-child(2) .SecondCardUpper .ImgRapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .5s;
}

.blogCardHomeSection:nth-child(2) .SecondCardUpper:hover img {
    width: 110%;
    height: 110%;
    transition: .5s;
}

.homeBlogDate {
    font-size: 12px;
    color: #fff;
}

.homeBlogTitle {
    font-weight: normal;
    font-size: 22px;
}

.SecondCardUpper .secondBlogContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
    width: 50%;
}

.secondCardLower {
    flex: 1;
    height: 40%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
    background-color: #2251FF;
    color: white;
    padding-left: 15px;
    flex-direction: column;
}

.blogCardHomeSection:nth-child(3) {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: flex-end;
    overflow: hidden;
}

.blogCardHomeSection:nth-child(3) .imageRapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.blogCardHomeSection:nth-child(3) img {
    width: 100%;
    height: 100%;
    transition: .5s;
    object-fit: cover;
}

.blogCardHomeSection:nth-child(3):hover img {
    width: 110%;
    height: 110%;
    transition: .5s;
}

.blogCardHomeSection:nth-child(1):hover h1 {
    color: #2251FF;
    cursor: pointer;
}

.blogCardHomeSection:nth-child(3):hover h1 {
    color: #2251FF;
    cursor: pointer;
}

.blogCardHomeSection:nth-child(4):hover h1 {
    color: #2251FF;
    cursor: pointer;
}

.blogCardHomeSection:nth-child(1):hover p {
    color: #2251FF;
    cursor: pointer;
}

.blogCardHomeSection:nth-child(3):hover p {
    color: #2251FF;
    cursor: pointer;
}

.blogCardHomeSection:nth-child(4):hover p {
    color: #2251FF;
    cursor: pointer;
}

.SecondCardUpper:hover h1 {
    color: #2251FF;
    cursor: pointer;
}

.SecondCardUpper:hover p {
    color: #2251FF;
    cursor: pointer;
}

.secondCardLower:hover h1 {
    color: black;
    cursor: pointer;
}

.secondCardLower:hover p {
    color: black;
    cursor: pointer;
}

.blogCardHomeSection:nth-child(3) .homeBlogContent {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 3px 3rem 19px;
    gap: 1rem;
}

/* ==================================================>;
4th no blog section 
=====================================================> ;
*/
.blogCardHomeSection:nth-child(4) {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: center;
}

.blogCardHomeSection:nth-child(4) .imageRapper {
    width: 50%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blogCardHomeSection:nth-child(4) .imageRapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .5s;
}

.blogCardHomeSection:nth-child(4):hover .imageRapper img {
    width: 110%;
    object-fit: cover;
    height: 110%;
    transition: .5s;
}

.blogCardHomeSection:nth-child(4) .homeBlogContent {
    width: 50%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.blogCardHomeSection:nth-child(4) .lastblogcardcontents {
    width: 100%;
    height: 80%;
    gap: 1rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 25px 17px;
}

.blogCardHomeSection:nth-child(4) .homeBlogContent .BlogReadMore {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2251FF;
    font-size: 16px;
    text-decoration: none;
    color: white;
}

/* ==================================================>;
 how we work section style
  =====================================================>; */
.HWWSection {
    display: block !important;
    position: fixed;
    height: 400vh;
    width: 100vw;
    background: transparent;
    /* opacity: 0; */

    top: 0;
    left: 0;
    top: 0;
    z-index: -1;
    animation-name: FadeDown;
    animation-duration: 1s;
}

@keyframes FadeDown {
    from {
        top: 0;
    }

    to {
        top: 15rem;
    }

}

.backdots {
    position: absolute;
    height: 2px;
    width: 2px;
    background-color: white;
    border-radius: 50%;

}


.spaceEffect {
    position: absolute;
    height: 100%;
    width: 100%;
    background: transparent;
    z-index: 0;
}


/*
===============================>;
timeline section
===============================>;
*/
.HWWSection .timeLine {
    width: 100%;
    height: 100%;
    z-index: 25;
    position: absolute;

}

.HWWSection .timeLine .timeLineCircle {
    height: 87rem;
    width: 87rem;
    border: 2px solid orange;
    border-radius: 50%;
    margin: auto;
    position: relative;
    top: 172px;
}




::-webkit-scrollbar {
    /* display: none; */
    width: 10px;
    height: 30px;
    background-color: #f0f0f0;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: var(--btn-color-blue);
}

.earth {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

.HWWBars {
    position: absolute;
    height: 3rem;
    width: 100%;
    left: 0;
}

.circletimeline {
    width: 100vw;
    height: 100vw;
    border: 2px solid white;
    border-radius: 50%;
    position: relative;
    margin: auto;
    z-index: 40;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    inset: 0;
    transition: 0.5s;
    top: 8vw;
}

.circletimeline .circletimelineDots {
    width: 2rem;
    border-radius: 50%;
    position: absolute;
    transform: rotate(calc(45deg * var(--i)));
    color: white;
    inset: 8px;
    left: 48.8vw;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.circletimeline .dots {
    position: absolute;
    width: 5rem;
    height: 6.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    margin: -59px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}


.earthSec {
    width: 100%;
    height: 100vh;
    overflow: hidden !important;
}

.timelineItems {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3px;
    align-items: center;
    width: 100%;
    height: 100%;
}



.clickers {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: blue;
    cursor: pointer;
    transition: 0.5s;
}

.yearInner {
    position: absolute;
    z-index: 100000;
    display: block !important;
}

.year {
    display: none;
    font-size: 4rem;
    font-family: 'Anton', sans-serif;
    opacity: 0.89;
}


.detilSec {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.timelineDetailCard {
    position: relative;
    width: 70vw;
    height: 50vh;
    height: 50vh;
    border-radius: 13px;
    margin: auto;
    margin-top: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 15px;
    gap: 16vh;
    z-index: 11111;
}


/* ======================>
timeline Mid Box
======================> */
.timelineHeadingSec {
    height: 20%;
    color: white;
    font-size: 15px;
}

.timelineHeadingSec h1 {
    font-family: 'Anton', sans-serif;
    font-size: 100px;
    text-transform: uppercase;
    letter-spacing: 2px;
    opacity: 0.2;
    text-wrap: nowrap;
    position: absolute;
    bottom: -6%;
    left: 0;
    width: 100%;
    text-align: center;
}

.activeBtn {
    width: 6rem;
    height: 6rem;
    box-shadow: 0px 0px 22px 7px blue;
    opacity: 1;
    position: relative;
    opacity: 1;
}


.timelineDescSec {
    color: white;
    font-size: 38px;
    text-align: center;
}

.FullAnimSec {
    height: 630vh;
    position: relative;
    overflow: hidden;
}



.earthSec {
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.CircleBackVideo {
    position: fixed;
    background: var(--backgroundVideo);
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: 0;
}

.CircleBackVideo video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
}

.extraTimeline {
    width: 100%;
    height: 595vh;
    position: relative;
    background-color: transparent;
}

/* ===================>
loader
======================> */
.loader {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000000000;
    overflow: hidden;
    scroll-behavior: smooth;
    flex-direction: column;
    background-color: #2b2d3a;
    gap: 2rem;
}

.loader img {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    animation-name: loader;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    filter: invert(1);
}

@keyframes loader {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* ==========================>
Time Line Fixed class 
==========================> */
.howWeWorkQuote {

    top: 40vh;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 10rem;
    font-family: 'Anton', sans-serif;
    font-stretch: expanded;
    text-transform: uppercase;
    opacity: 0.7;
    position: absolute;
    transition: 0.5s ease-in;
}

.Worktrans {
    opacity: 0 !important;
    display: none;
    transition: 0.5s ease-in;
    animation-name: QuoteChange;
    animation-duration: 0.5s;
    top: 0;
}

@keyframes QuoteChange {
    from {
        top: 40vh;
    }

    to {
        top: 0;
    }
}

.timeLineChange {
    /* display: flex !important; */
    /* position: fixed !important; */
    top: 0;
    left: 0;
    z-index: 1 !important;
    width: 100% !important;
    background: transparent !important;
    transition: 0.2s;
    opacity: 1;
    animation-name: FadeInUp;
    animation-duration: 0.2s;
    animation-timing-function: linear;
    /* animation-iteration-count: ; */

}

@keyframes FadeInUp {
    from {
        top: 10rem;

    }

    to {
        top: 0rem;
    }
}


/*
=================================================================
Change how we section according to Screan Size
=================================================================
*/


#workflow {
    display: none;
    background-color: transparent;
    height: fit-content;
    position: relative;
}

@media(max-width:1200px) {
    .HWWSection .timeLine {
        display: none;
    }

    .detilSec {
        display: none;
    }

    #workflow {
        display: block;
    }

    .HWWSection {
        display: block !important;
        position: relative !important;
        animation: unset !important;
        transition: 0.5s;
        height: fit-content !important;
    }

    .timeLineChange {
        position: relative !important;
        animation: unset !important;
        transition: unset;
        height: fit-content !important;
    }

    .howWeWorkQuote {
        display: none !important;
    }

    .earthSec {
        display: none;
    }

    .extraTimeline {
        /* height: fit-content; */
        display: none;
    }
}



/*
==============================================================
Defined Css
==============================================================
*/

#roadCanvas {
    height: 3rem;
    width: 3rem;
}

@media (max-width:770px){
    #workflow .body>div.visible{
        padding-left: 0 !important  ;
    }
    .WorkFlowCards{
        padding-left: 0 !important  ;
    }
}