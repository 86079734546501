#workflow {
    padding: 40px;
}

#workflow .head {
    text-align: start;
    font-size: 38px;
    margin: auto;
    font-family: "Montserrat", sans-serif;
    width: 83%;
}

.headingWorkFlow {
    margin: 4rem 0;
    width: 100%;
    height: fit-content;
}

.Rapper {
    width: 100%;

}

#workflow .body {
    display: flex;
    flex-direction: column;
    gap: 6rem;
    position: relative;
    margin-left: 34px;
    padding-bottom: 5rem;
    width: 80%;
    margin: auto;
}

#workflow .left-bar {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    padding: 0 19px;
    z-index: 1;
}

#workflow .left-bar .line {
    border-left: 2px solid white;
    height: 0;
    transition: height .3s;
}

#workflow .body>div {
    display: grid;
    grid-template-columns: min-content 1fr;
    align-items: center;
    gap: 30px;
    opacity: .2;
    transition: opacity .3s;
}

#workflow .body>div.visible {
    opacity: 1;
    padding-left: 18px;
}

#workflow .mark {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid white;
    font-size: 1.5em;
    transition: all .3s;
    z-index: 2;
}

.WorkFlowCards {
    padding-left: 18px;
}

#workflow .body>div.visible .mark {

    color: white;
    background: blue;
    box-shadow: 0 0 10px 10px blue;
    border: none;
    transition: 0.5s ease;
}

#workflow .content {
    flex-grow: 1;
    padding: 24px;
    border-radius: 15px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    padding: 0 !important;
}

#workflow .content img {
    display: none;
}

#workflow .content h2 {
    font-style: italic;
    margin-bottom: 10px;
}

#workflow .contentText {
    color: #ffff;
}

#workflow .contentText p {
    font-size: 14px;
}

#workflow .contentText h2 {
    font-size: 17px;
}

#top,
#bottom {
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
}

@media(max-width:700px) {
    #workflow .content {
        flex-direction: column;
    }
}

@media(max-width:500px) {
    #workflow .body {
        /* margin-left: 0; */
        align-items: center;
    }

    #workflow .body>div {
        gap: 0px;
    }

    #workflow {
        padding: 0px;
    }
}

@media(max-width:457px) {

    /* #workflow .left-bar {
        display: none;
    }*/
    #workflow .mark {
        position: absolute;
        width: 30px;
        height: 30px;
        left: 10px;
        margin-right: 20px;
    }

    #workflow .content {
        margin-right: 0px;
        width: 250px;
        position: relative;
        left: 20px;
    }

    #workflow .left-bar .line {
        position: absolute;
        left: 25px;
    }
}