.Martian_Blog_page a {
    text-decoration: none;
    color: #2251ff;
}

.Martian_Blog_page {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
}


/* 
==============================================
          previous page starts here 
===============================================
 */
.previous_page {
    height: 20px;
    width: 41vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 3rem 0px 1rem 0px;
}

.previous_page a {
    text-decoration: none;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
}

.previous_page a img {
    height: 1.2rem;
    width: 1.2rem;

}

.previous_page a h4 {
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 0;
    transition-delay: 0.2s;
}

.previous_page a h4:hover {
    cursor: pointer;
    border-bottom: 2px solid black;
    transition-delay: 0.2s;
}

/* 
==============================================
           prevous page ends here
===============================================
*/




/* 
===================================================
                header starts here  
====================================================
*/
.header {
    width: 41vw;
}

.header h2 {
    font-size: 3.5rem;
}

.header .social_media {
    margin-top: 2.5rem;
}


.social_media a {
    text-decoration: none;
}

.header .social_media svg {
    width: 2.5rem;
    height: 2.5rem;
    transition: all 0.12s linear;
}

.header svg:hover {
    cursor: pointer;
    fill: #2251ff;
    transition: all 0.12s linear;
    transform: translateY(-2px);
}

.main-blog {
    width: 41vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 2.5rem;
}



.main-blog .header_img img {
    width: 41vw;
    height: 42vh;
    object-fit: cover;
}

.main-blog .blog-content {
    width: 41vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 2.5rem;
}

.blog-content .flex {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
}

.blog-content .flex img {
    width: 35vh;
    height: 35vh;
    object-fit: cover;
}



.main-blog .blog-content p {
    font-size: 1.3rem;
    color: #333333;
    font-weight: 300;
    line-height: 2.2rem;
    letter-spacing: 1.3px;
    text-wrap: wrap;
}

.blog-content ul li {
    font-size: 1.4rem;
    color: #333333;
    font-weight: 300;
    line-height: 2.2rem;
    letter-spacing: 1.3px;
    margin: 0px 0px 1rem;
    margin-left: 2rem;
}

.main-blog .blog-content .middle-point {
    width: 41vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    border-top: 2px solid #00000049;
    border-bottom: 2px solid #00000049;
    padding: 2rem 0rem;
}

.main-blog .blog-content .middle-point img {
    width: 28vh;
    height: 25vh;
    object-fit: cover;
    margin-right: 2rem;
}

.main-blog .blog-content .middle-point h2 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
}

.main-blog .blog-content .Quotes {
    font-size: 2.5rem;
    font-weight: 700;
    word-spacing: 3px;
    color: #2252ff;
    margin: 2rem 0rem;
}

.main-blog .blog-content .Quotes span {
    font-size: 1.5rem;
    color: #4178ef;

}

.main-blog .blog-content .para-highlight {
    border-bottom: 2px solid #2251ff;
    cursor: pointer;
}

.main-blog .blog-content .para-highlight:hover {
    color: rgb(247, 247, 247);
    background-color: #2252ff;
    transition: .12s linear;
}

.main-blog .blog-content .main-point {
    font-size: 2.3rem;
    font-weight: 700;
    margin: 1.5rem 0rem;
}

.main-blog .blog-content .para-highlight-last {

    cursor: pointer;
    color: #2251ff;
}

.main-blog .blog-content .para-highlight-last:hover {
    color: rgb(247, 247, 247);
    background-color: rgba(29, 29, 233, 0.815);


}

/*
======================================
Footer Section
======================================
*/

.explore-section {
    width: 41vw;
    margin: auto;
    height: 6rem;
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif !important;
}



.explore-section a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.8rem;
}



.explore-section a h1 {
    font-size: 0.9rem;
    color: #000000;
    text-transform: uppercase;
    letter-spacing: 10px;
    transition: all 0.2s linear;
}



.explore-section a:hover h1 {
    cursor: pointer;
    color: #2251ff;
    transition: all 0.2s linear;
}


.explore-section svg {
    transition: 0.2s;
}


.explore-section a:hover svg {
    cursor: pointer;
    fill: #2251ff;
    transition: all 0.2s linear;
    transform: translateX(15px);
}


.stay-update {
    height: 20rem;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 2.5rem;
    background-color: #f0f0f0;
    padding-top: 4rem;
}

.stay-update h2 {
    font-size: 2.5rem;
    font-weight: 400;
}

.stay-update h3 {
    font-size: 0.9rem;
    font-weight: 300;
    margin: 1rem 0rem;
    letter-spacing: 1px;
    word-spacing: 0px;
}

.stay-update .button {
    /* height: 100%; */
    margin: 1.5rem 0rem 3rem 0rem;
    display: flex;
    align-items: center;
}

.stay-update .button .input-field {
    padding: 0rem 0rem 0rem 1.3rem;
    height: 2.8rem;
    width: 15rem;
    font-size: 1rem;
    margin: 0rem 0.3rem;
    outline: none;
    border-radius: 0;
    border: 1px solid gray;
}

.stay-update .button .input-field:hover {
    border: 1.5px solid #2251ff;
    transition: all .3s;
}

.stay-update .button button {
    font-size: 0.9rem;
    padding: 0.7rem 1rem;
    color: #ffffff;
    background-color: #2251ff;
    border: none;
    transition: all .3s;
}

.stay-update .button button:hover {
    color: #2251ff;
    cursor: pointer;
    background-color: #fff;
    transition: all .3s;
    transition-delay: 0.2s;
    border: 1.3px solid #2251ff;
}




/* 
======================================
             Responsive 
======================================
*/

@media (max-width:1280px) {
    .Martian_Blog_page .previous_page {
        width: 55vw;
    }

    .Martian_Blog_page .previous_page a img {
        width: 1.2rem;
        height: 1.2rem;
    }

    .Martian_Blog_page .previous_page h4 {
        font-size: 1rem;
    }

    .Martian_Blog_page .header {
        width: 55vw;
    }

    .Martian_Blog_page .header .social_media {
        margin-top: 1rem;
    }

    .Martian_Blog_page .header h2 {
        font-size: 3rem;
    }

    .Martian_Blog_page .header .social_media svg {
        width: 2.2rem;
        height: 2.2rem;
    }

    .Martian_Blog_page .main-blog {
        width: 55vw;
        margin-top: 1rem;
    }

    .Martian_Blog_page .main-blog .header_img {
        width: 55vw;
    }

    .Martian_Blog_page .main-blog .header_img img {
        width: 55vw;
        height: 60vh;
        object-fit: cover;

    }

    .Martian_Blog_page .main-blog .blog-content {
        width: 55vw;
        margin-top: 1rem;
    }

    .Martian_Blog_page .main-blog .blog-content .main-point {
        font-size: 2rem;
        color: #000;
    }

    .Martian_Blog_page .main-blog .blog-content p {
        font-size: 1.2rem;

    }

    .blog-content ul li {
        font-size: 1.2rem;
        line-height: 1.8rem;
        letter-spacing: 1.3px;
    }

    .main-blog .blog-content .middle-point {
        width: 55vw;

    }



    .Martian_Blog_page .main-blog .blog-content .Quotes {
        font-size: 1.5rem;
    }

    .Martian_Blog_page .main-blog .blog-content .para-highlight-last {
        font-weight: 500;
    }

    .explore-section {
        width: 55vw;
    }
}

@media (max-width:1180px) {
    .Martian_Blog_page .previous_page {
        width: 70vw;
    }

    .Martian_Blog_page .header {
        width: 70vw;
    }

    .Martian_Blog_page .main-blog {
        width: 70vw;
    }

    .Martian_Blog_page .main-blog .header_img {
        width: 70vw;
    }

    .Martian_Blog_page .main-blog .header_img img {
        width: 70vw;
        height: 50vh;
        object-fit: cover;
    }

    .Martian_Blog_page .main-blog .blog-content {
        width: 70vw;
    }

    .main-blog .blog-content .middle-point {
        width: 70vw;

    }

    .explore-section {
        width: 70vw;
    }
}

@media (max-width:1002px) {
    .Martian_Blog_page .header {
        width: 73vw;
    }

    .Martian_Blog_page .header h2 {
        font-size: 2.8rem;
    }

    .Martian_Blog_page .main-blog {
        width: 73vw;
    }

    .main-blog .blog-content .middle-point {
        width: 73vw;

    }

    .explore-section {
        width: 73vw;
    }

}

@media (max-width:900px) {

    .Martian_Blog_page .main-blog {
        width: 75vw;
    }

    .Martian_Blog_page .main-blog .blog-content {
        width: 75VW;
    }

    .Martian_Blog_page .main-blog .header_img img {
        width: 75vw;
        height: 45vh;
        object-fit: cover;
    }

    .Martian_Blog_page .main-blog .blog-content .main-point {
        font-size: 1.8rem;
    }

    .Martian_Blog_page .main-blog .blog-content {
        margin-top: 1.5rem;
    }

    .main-blog .blog-content .middle-point {
        width: 75vw;

    }

    .blog-content ul li {
        font-size: 1.2rem;
        line-height: 1.2rem;
        letter-spacing: 1.3px;
    }

    .explore-section {
        width: 75vw;
    }
}

@media (max-width:825px) {

    .Martian_Blog_page .previous_page {
        width: 80vw;
    }

    .Martian_Blog_page .header .social_media {
        margin-top: 1rem;
    }

    .Martian_Blog_page .header {
        width: 80vw;
    }

    .Martian_Blog_page .main-blog {
        width: 85vw;
    }

    .Martian_Blog_page .main-blog .blog-content {
        width: 80vw;
        margin-top: 1rem;
    }

    .Martian_Blog_page .main-blog .header_img {
        width: 80vw;
    }

    .Martian_Blog_page .main-blog .header_img img {
        width: 80vw;
        height: 45vh;
        object-fit: cover;
    }

    .main-blog .blog-content .middle-point {
        width: 80vw;

    }

    .explore-section {
        width: 80vw;
    }
}

@media (max-width:712px) {

    .Martian_Blog_page .previous_page {
        width: 85vw;
    }

    .Martian_Blog_page .header .social_media {
        margin-top: .5rem;
    }

    .Martian_Blog_page .header {
        width: 85vw;
    }

    .Martian_Blog_page .header h2 {
        font-size: 2.2rem;
    }

    .Martian_Blog_page .main-blog {
        width: 85vw;
    }

    .Martian_Blog_page .main-blog .header_img {
        width: 85vw;
    }

    .Martian_Blog_page .main-blog .header_img img {
        width: 85vw;
        height: 55vh;
        object-fit: cover;
    }

    .Martian_Blog_page .main-blog .blog-content {
        width: 85vw;
        margin-top: 0.5rem;
    }

    .Martian_Blog_page .main-blog .main-point {
        font-size: 1.5rem;
    }

    .main-blog .blog-content .middle-point {
        width: 85vw;

    }

    .main-blog .blog-content .middle-point h2 {
        font-size: 2rem;

    }

    .explore-section {
        width: 85vw;
    }

}

@media (max-width:610px) {

    .Martian_Blog_page .previous_page {
        width: 90vw;
    }

    .Martian_Blog_page .header .social_media {
        margin-top: .5rem;
    }

    .Martian_Blog_page .header {
        width: 90vw;
    }

    .Martian_Blog_page .header h2 {
        font-size: 2.2rem;
    }

    .Martian_Blog_page .main-blog {
        width: 90vw;
    }

    .Martian_Blog_page .main-blog .header_img {
        width: 90vw;
    }

    .Martian_Blog_page .main-blog .header_img img {
        width: 90vw;
        height: 50vh;
        object-fit: cover;
    }

    .Martian_Blog_page .main-blog .blog-content {
        width: 90vw;
        margin-top: 0.5rem;
    }

    .Martian_Blog_page .main-blog .blog-content .main-point {
        font-size: 1.5rem;
    }

    .blog-content ul li {
        margin-left: 0px;
        line-height: 1.8rem;
    }

    .Martian_Blog_page .main-blog .blog-content .flex {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }



    .main-blog .blog-content .middle-point {
        width: 90vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .main-blog .blog-content .middle-point h2 {
        font-size: 2rem;

    }

    .explore-section {
        width: 90vw;
    }

}

@media (max-width:440px) {
    .Martian_Blog_page .previous_page a img {
        width: 1rem;
        height: 1rem;
    }

    .Martian_Blog_page .previous_page h4 {
        font-size: 0.8rem;
    }

    .Martian_Blog_page .header h2 {
        font-size: 2rem;
    }

    .Martian_Blog_page .main-blog .header_img img {
        width: 90vw;
        height: 30vh;
        object-fit: cover;
    }

    .Martian_Blog_page .main-blog .blog-content p {
        font-size: 1rem;
        line-height: 1.8rem;
    }

    .blog-content ul li {
        font-size: 1rem;
        line-height: 1.5rem;
        letter-spacing: 1px;
        margin-left: 10px;
    }

    .Martian_Blog_page .main-blog .blog-content .main-point {
        font-size: 1.4rem;
    }

    .Martian_Blog_page .main-blog .blog-content .flex {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    .main-blog .blog-content .middle-point h2 {
        font-size: 1.5rem;

    }


    .explore-section a h1 {
        letter-spacing: 2px;
    }

    .stay-update {
        height: 15rem;
        width: 100vw;
        margin-top: 1rem;
        padding-top: 1rem;
    }



    .stay-update h3 {
        font-size: 0.7rem;
        letter-spacing: 0px;
    }

    .stay-update h2 {
        font-size: 1.5rem;
    }

    .stay-update .button {
        margin: 1rem 0rem 1rem 0rem;
        display: flex;
        align-items: center;
    }

    .stay-update .button .input-field {
        padding: 0rem 0rem 0rem 0.7rem;
        height: 2.8rem;
        width: 10rem;
        font-size: 0.7rem;
        margin: 0rem 0.2rem;
        outline: none;
        border-radius: 0;
        border: 1px solid gray;
    }

    .stay-update .button button {
        font-size: 0.7rem;
        padding: 0.9rem 0.7rem;

    }

    @media (max-width:312px) {
        .Martian_Blog_page .main-blog .blog-content p {
            font-size: 0.8rem;
            line-height: 1.5rem;
        }

        .blog-content ul li {
            font-size: 0.8rem;
            line-height: 1.5rem;
            letter-spacing: 1px;

        }
    }

}