.blogHero {
    width: 100%;
    margin: auto;
    background-color: #F0F0F0;
    padding-top: 32px;
}


.blogSection {
    width: 100%;
    height: 100%;
    background-color: #F0F0F0;
    padding-bottom: 4rem;
}

.ParentRapper {
    width: 80%;
    margin: auto;
    height: fit-content;
    background-color: var(--dark-back);
}

.blogback {
    width: 100%;
    height: 100%;
    margin: auto;
}

.blogHero * {
    font-family: 'Inter', sans-serif;
}


.blogHero .heroBody {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;


}

.heroBody a {
    text-decoration: none;
    color: black;
}

.blogHero .heroBody h1 {
    font-size: 3.5rem;
    font-weight: normal;
    color: var(--light-text);
}

.blogHero .blogCards .cardBody {
    /* height: 100vh; */
    width: 100%;
    margin: auto;
    /* border: 2px solid green; */

}

.blogHero .blogCards .cardSectionheader {
    border-bottom: 1px solid #D0D0D0;
    padding-bottom: 2px;

}

.blogHero .blogCards .cardBody .headerTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 9rem;
    margin-bottom: 10px;
}

.blogHero .blogCards .cardBody .headerTitle h1 {
    font-size: 16px;
    font-weight: normal;
    color: #8D8D8E;
}

.blogHero .blogCards .cardBody .headerTitle .blogCardSectionHeaderFilter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.blogHero .blogCards .cardBody .headerTitle .blogCardSectionHeaderFilter .blogCardHeaderFilterBtn {
    width: 3rem;
    font-size: 15px;
    background-color: transparent;
    border: none;
    outline: none;
    color: #2251FF;
    cursor: pointer;
}

.blogHero .blogCards .cardBody .headerTitle .blogCardSectionHeaderFilter .blogCardSectionHeaderFiltertitle {
    color: #FFFF;
    font-size: 12px;
}

/* design of blog cards */
.cardBody .blogCards {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 2rem;
    grid-row-gap: 2rem;
    grid-column-gap: 2rem;
    place-items: start;
}

/* =====================================
Blog Page Responsive
===================================== */

@media (max-width:990px) {

    .blogHero .heroBody h1 {
        font-size: 2.5rem;
    }

    .cardBody .blogCards {
        grid-template-columns: repeat(2, 1fr) !important;
    }


}

/*
==========================================================
Subscribe Container.
===========================================================
*/

.blogSection .blogCards .SubscribeCont {
    background: #2251FF;
    height: 95%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}

.blogSection .blogCards .SubscribeCont h1 {
    color: #FFF;
    text-align: center;
    font-family: 'Roboto';
    font-size: 27px;
    font-weight: 400;
}

.blogSection .blogCards .SubscribeCont p {
    color: #FFF;
    text-align: center;
    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    padding: 1px 40px 1px 40px;
}

.blogSection .blogCards .SubscribeCont .inputArrowCont {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.blogSection .blogCards .SubscribeCont .inputArrowCont input {
    width: 234.621px;
    height: 40px;
    flex-shrink: 0;
    color: #525252;
    font-family: 'Roboto';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border: 1px solid #2251FF;
    outline: none;
    padding-left: 20px;
}

.blogSection .blogCards .SubscribeCont .inputArrowCont img {
    padding-left: 20px;
    cursor: pointer;
    transition: 0.5s;
}

.blogSection .blogCards .SubscribeCont .inputArrowCont img:hover {
    transform: translateX(10px);
}

.blogSection .blogCards .sbResMobVer {
    display: none;
}

@media(max-width:1200px) {
    .blogSection .blogCards .SubscribeCont .inputArrowCont input {
        width: 100%;
        padding-left: 5px;
    }

    .blogSection .blogCards .SubscribeCont .inputArrowCont img {
        width: fit-content;
        padding-left: 0;
    }
}

@media (max-width:1040px) {

    .cardBody .blogCards {
        width: 100%;
        /* height: fit-content; */
        display: flex !important;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .card {
        width: 20rem;
        height: fit-content;
    }

    .blogSection .blogCards .SubscribeCont .inputArrowCont {
        width: 100%;
    }

    .blogSection .blogCards .SubscribeCont {
        padding: 3rem 0;
        height: fit-content;
    }
}

@media (max-width:899px) {
    .cardBody .blogCards {

        justify-content: space-between;
    }

    .card {
        width: 100%;
        height: fit-content;
    }

    .blogSection .blogCards .sbResDptVer {
        display: none;
    }

    .blogSection .blogCards .sbResMobVer {
        display: flex;
    }

}

@media (max-width:450px) {
    .blogHero .blogCards .cardBody .headerTitle h1 {
        font-size: 11px;
    }

    .blogHero .blogCards .cardBody .headerTitle {
        margin-top: 3rem;
    }

    .blogSection .blogCards .SubscribeCont .inputArrowCont input {
        width: 66%;
        padding-left: 17px;
    }

    .blogSection .blogCards .SubscribeCont p {
        padding: 20px 0;
    }

    .blogSection .blogback .blogHero .heroBody .resMobVer {
        display: none;
    }

    .blogHero .heroBody h1 {
        font-size: 1.5rem;
    }

    .blogHero .blogCards .cardBody .headerTitle {
        margin-top: 20px;
    }

    .blogHero .blogCards .cardBody .headerTitle {
        margin-top: 35px;
    }

    .blogHero {
        padding-top: 21px;
    }
}