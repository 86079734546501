.homePageSection .homeHeaderSec {
    background-color: var(--dark-back) !important;
}

.homePageSection .homeHeaderContent .headerBars {
    background: linear-gradient(var(--dark-back), transparent) !important;
}

.homePageSection .homeHeaderContent .headerBarsRight {
    background: linear-gradient(to left, var(--dark-back), transparent) right !important;
}

.homePageSection .headerRapper .homeHeaderContent p {
    color: var(--home-hero-txt) !important;
}

.homePageSection {
    background-color: var(--dark-back) !important;
}

.homePageSection .homePageCardsSection .homePageCards .cardContentRapper p,
.homePageSection .homeServices .homeServicesRapper .homeServiceCard p,
.blogCardHomeSection .homeBlogContent p {
    color: white !important;
}


.homePageSection .homePageCardsSection .homePageCards .homePageCard img,
.homePageSection .homeServices .homeServicesRapper .homeServiceCard img {
    filter: invert(1) !important;
}