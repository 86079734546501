.careerCont .careerHeroSection {
    height: 533px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    background: #161C2D;
    z-index: 0;

}

.careerCont .careerHeroSection .headingSection {
    height: 560px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
}

.careerCont .careerHeroSection h1 {
    color: #FFF;
    font-family: 'Roboto';
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 76px;
}

.careerCont .careerHeroSection button {
    width: 208.552px;
    height: 48px;
    background: #2251FF;
    color: #FFF;
    text-align: center;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    border: 0;
    cursor: pointer;
    margin: 30px 10px;
}

.careerCont .careerHeroSection::before {
    content: "";
    position: absolute;
    background: url('../../../../Images/Image/career.png') no-repeat center center/cover;
    top: 0px;
    /* right: 0; */
    /* left: 0; */
    height: 100%;
    width: 100vw;
    z-index: -1;
}

.careerCont .joinMartianSectionWrapper {
    background: #161C2D;
}


.careerCont .joinMartianSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 750px;
    width: 100%;
    margin: auto;
}

.careerCont .joinMartianSection .textInfoCont {
    height: 580px;
    width: 46%;
}

.careerCont .joinMartianSection .textInfoCont h1 {
    color: #FFF;
    font-family: 'Roboto';
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
}

.careerCont .joinMartianSection .textInfoCont .desc {
    width: 415px;
    color: #fff;
    font-family: 'Roboto';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    margin-top: 26px;
}

.careerCont .joinMartianSection .textInfoCont .lookFor {
    color: #fff;
    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    padding-top: 65px;
}

.careerCont .joinMartianSection img {
    width: 584px;
    height: 584px;
}

.careerCont .yourCareerCont {
    background: #161C2D;
    padding-bottom: 77px;
}

.careerCont .yourCareerCont .title {
    color: #FFF;
    text-align: center;
    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    padding: 25px 0;
}

.careerCont .yourCareerCont .yourCareerWrapper {
    display: flex;
}

.careerCont .yourCareerCont .yourCareerWrapper .interviewProcess,
.careerCont .yourCareerCont .yourCareerWrapper .campus {
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* padding-left: 60px; */
    align-items: center;
}

.careerCont .yourCareerCont .yourCareerWrapper .interviewProcess p,
.careerCont .yourCareerCont .yourCareerWrapper .campus p {
    color: #000;
    font-family: 'Roboto';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
}

.careerCont .yourCareerCont .yourCareerWrapper .campus p,
.careerCont .yourCareerCont .yourCareerWrapper .campus h1 {
    color: #FFF;
    padding: 20px 0;
}

.careerCont .yourCareerCont .yourCareerWrapper .interviewProcess p,
.careerCont .yourCareerCont .yourCareerWrapper .interviewProcess h1 {
    padding: 20px 0;
}

.interviewProcess.textWrapper:nth-child(1) {
    width: 100%;
}

.textWrapper:nth-child(2) {
    width: 100%;
}

.careerCont .yourCareerCont .yourCareerWrapper .textWrapper p,
h1 {
    color: #fff;
}

.careerCont .yourCareerCont .yourCareerWrapper h1 {
    font-family: 'Roboto';
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
}

.careerCont .yourCareerCont .yourCareerWrapper .interviewProcess {
    background: #161C2D;
    height: 408px;
    width: 50%;
    border-color: gray;
    border-style: solid;
    border-width: 1px 1px 1px 0px;
}

.careerCont .yourCareerCont .yourCareerWrapper .campus {
    background: #2251FF;
    height: 408px;
    width: 50%;
    position: relative;
}

/*
======================================================
Make the Page Responsive
======================================================
*/

@media(max-width:1349px) {
    .careerCont .joinMartianSection img {
        width: 484px;
        height: 484px;
    }

    .careerCont .joinMartianSection {
        align-items: flex-start;
        padding-top: 10rem;
        width: 100%;
    }
}


@media(max-width:990px) {
    .careerCont .joinMartianSection img {
        width: 384px;
        height: 384px;
    }

    .careerCont .joinMartianSection {
        align-items: flex-start;
        padding-top: 10rem;
        width: 100%;
    }

    .careerCont .careerHeroSection h1 {
        font-size: 42px;
    }

    .careerCont .yourCareerCont .yourCareerWrapper h1 {
        font-size: 36px;
    }
}

@media(max-width:823px) {
    .careerCont .joinMartianSection img {
        width: 230px;
        height: 230px;
    }

    .careerCont .joinMartianSection .textInfoCont h1 {
        font-size: 35px;
    }

    .careerCont .joinMartianSection .textInfoCont .desc {
        font-size: 15px;
    }

    .careerCont .yourCareerCont .yourCareerWrapper .interviewProcess,
    .careerCont .yourCareerCont .yourCareerWrapper .campus {
        padding: 0;
    }

}

@media(max-width:657px) {
    .careerCont .joinMartianSection img {
        display: none;
    }

    .careerCont .joinMartianSection {
        align-items: center;
        padding-top: 0rem;
    }

    .careerCont .joinMartianSection .textInfoCont {
        height: auto;
        width: 90%;
    }

    .careerCont .yourCareerCont .yourCareerWrapper h1 {
        font-size: 25px;
    }

    .careerCont .yourCareerCont .yourCareerWrapper .interviewProcess,
    .careerCont .yourCareerCont .yourCareerWrapper .campus {
        height: 300px;
    }

    .careerCont .careerHeroSection h1 {
        font-size: 25px;
        line-height: 2rem;
        margin-bottom: 25px;
    }

    .careerCont .careerHeroSection button {
        width: 135px;
        margin: 0;
    }

}

@media(max-width:495px) {
    .careerCont .yourCareerCont .yourCareerWrapper {
        flex-direction: column;
    }

    .careerCont .yourCareerCont .yourCareerWrapper .interviewProcess,
    .careerCont .yourCareerCont .yourCareerWrapper .campus {
        width: 100%;
        background-color: transparent;
    }

    .careerCont .yourCareerCont .yourCareerWrapper .interviewProcess {
        border-right: none;
        border-bottom: none;
    }

    .careerCont .yourCareerCont .yourCareerWrapper .campus .textWrapper {
        position: relative;
        z-index: 10;
    }

    .careerCont .yourCareerCont .yourCareerWrapper .campus::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        background-color: var(--btn-color-blue);
        z-index: 0;
        left: -5vw;

    }

    .careerCont .joinMartianSection .textInfoCont h1 {
        font-size: 21px;
    }

    .careerCont .joinMartianSection .textInfoCont {
        height: 200px;
        width: 100%;
        margin: auto;
    }

    .careerCont .joinMartianSection .textInfoCont .desc {
        width: auto;
    }

    .careerCont .careerHeroSection {
        height: 367px;
    }

    .careerCont .joinMartianSection {
        height: 50vh;
    }

    .careerCont .careerHeroSection .headingSection {
        justify-content: center;
        width: 100%;
        margin: auto;
        height: fit-content;
    }

    .careerCont .careerHeroSection .headingSection div:nth-child(1) {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    .careerCont .careerHeroSection .headingSection div:nth-child(1) a button {
        font-size: 15px;
    }
}