.card {
    height: 100%;
    overflow: hidden;
    border: 2px solid rgb(128, 128, 128, 0.10);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    background-color: white;
    transition: 0.3s;
}

.card * {
    font-family: 'Inter', sans-serif;
    cursor: pointer;
}

.card:hover {
    box-shadow: 0 0 24px 0 rgb(255 255 255 / 42%);

}

.card .cardContaint a {
    text-decoration: none;
    font-family: 'Inter', sans-serif;
}

.card .cardContaint .Para {
    text-decoration: none;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #000;
}

.card .cardContaint .Para :hover{
    color: #2251FF;
}

.card .cardContaint h1:hover {
    color: #2251FF;
}

.card .cardContaint {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: space-between;
    padding: 11px;
}

.card img {
    width: 100%;
    height: 242px;
    object-fit: cover;

}

.card .cardContaint h1 {
    font-size: 22px;
    font-weight: normal;
    color: #000;
}

.card .cardUploadTime {
    font-size: 11px;
    color: gray;
    font-weight: normal;
}

.card .cardContaint .bogTags {
    font-size: 12px;
    display: flex;
    flex-direction: revert;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}