.jobSearchSection {
    width: 100%;
    height: auto;

}

.jobSearchSection * {

    font-family: 'Inter', sans-serif;
}


.jobRapper {
    background: #FFF;
    box-shadow: 0px 4px 18px 3px rgba(0, 0, 0, 0.02), 0px 10px 14px 1px rgba(0, 0, 0, 0.03), 0px 6px 6px -3px rgba(0, 0, 0, 0.04);
}

.jobSearchHeader {
    /* border: 2px solid red; */
    width: 70%;
    margin: auto;
    display: flex;
    height: 10rem;
    display: flex;
    align-items: center;
}

.jobSearchHeader input {
    width: 100%;
    height: 100%;
    border: 0;
    border-left: 0;
    border-right: 0;
    outline: none;
    font-size: 16px;
    padding: 10px;
    border-bottom: 1px solid #191823;
}

.jobSearchHeader input::placeholder {
    font-size: 1rem;
    font-weight: bold;
}

.jobSearchHeader .searchInput {
    width: 100%;
    height: fit-content;
    margin: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.jobSearchHeader button {
    position: absolute;
    right: 0;
    height: 3rem;
    width: 3rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.jobSearchHeader .searchInput .searchOptions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5rem;
}

.jobSearchHeader .searchInput .searchOptions .searchOptionSelector {
    border: none;
    outline: none;
    font-size: 15px;
    width: fit-content;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    padding-right: 11px;
    font-weight: 600;
}

.jobSearchSection .searchedContainBody {
    width: 100%;
    height: auto;
    margin-top: 3rem;
}

.jobSearchSection .searchedContainBody .searchedContain {
    width: 70%;
    margin: auto;
    /* border: 2px solid green; */
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    gap: 2rem;
}

.jobSearchSection .searchedContainBody .searchedContain .jobSearchFilter {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.jobSearchSection .searchedContainBody .searchedContain .jobSearchFilter .jobShortBtn {
    width: 50%;
    text-align: end;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

}

.jobSearchSection .searchedContainBody .searchedContain .jobSearchFilter .jobShortBtn {
    font-size: 11px;
}

.jobSearchSection .searchedContainBody .searchedContain .jobSearchFilter .jobShortBtn select {
    border: none;
    outline: none;
    background-color: transparent;
    padding-right: 10px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.87);

}

.jobSearchSection .searchedContainBody .searchedContain .jobSearchFilter .jobShortBtn span {
    color: rgba(0, 0, 0, 0.54);
    font-size: 11px;
}

.jobSearchSection .searchedContainBody .jobResultesCards {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 10rem;
    padding: 14px;
    border: 2px solid rgb(128, 128, 128, 0.10);
}

.jobSearchSection .searchedContainBody .jobResultesCards .role {
    font-weight: bold;
    font-size: 17px;
}

.jobSearchSection .searchedContainBody .jobResultesCards .jobSearchCardHeading {
    color: #76767E;
    font-size: 11px;
}

.jobSearchSection .searchedContainBody .jobResultesCards .jobLocation p {
    font-size: 14px;
}

.jobSearchSection .pageCounterSection {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    color: rgba(0, 0, 0, 0.54);

}

.jobSearchSection .pageCounterSection p {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.jobSearchSection .pageCounterSection p span {
    font-size: 16px;
    font-size: bold;
}

.jobSearchSection .pageCounterSection .pageStatus {
    font-size: 14px;
}

.jobSearchSection .pageCounterSection .pageChangerBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 3rem;

}

.jobSearchSection .pageCounterSection .pageChangerBtn button {
    font-size: 23px;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;

}

.jobSearchSection .pageCounterSection .pageChangerBtn button img {
    height: 2.5rem;
}

.jobSearchSection select {
    border: none;
    outline: none;
    cursor: pointer;
}