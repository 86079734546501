.NavbarCont {
    background-color: var(--dark-back);
}


.NavbarCont .logoSection p {
    color: #ffff;
}


.NavbarCont .listWrapper ul li a {
    color: #ffff !important;
}

.NavbarCont .listWrapper .getInTouch div {
    border-right: 2px solid white;
}

.NavbarCont .listWrapper .getInTouch p {
    color: #ffff;
}

.NavbarCont .listWrapper .getInTouch img,
.NavbarCont .logoSection a img {
    /* filter: invert(1); */
    transition: 0.2s;
    height: 35px;
    margin-top: 10px;
}

.NavbarCont .listWrapper .getInTouch a img:hover {
    transform: rotate(45deg);
    transition: 0.2s;
}