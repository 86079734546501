@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.ParentRapper-Privacy {
    width: 80%;
    margin: auto;
    height: -moz-fit-content;
    height: fit-content;
    background-color: var(--dark-back);
    color: #fff;
}

.ParentRapper-Privacy .heading {
    margin-top: 50px;
}

.ParentRapper-Privacy h1,
.ParentRapper-Privacy h2,
.ParentRapper-Privacy h3,
.ParentRapper-Privacy h4 {
    margin: 30px 0;
    font-family: "Montserrat", sans-serif;
}

.ParentRapper-Privacy .PrivacyPara {
    margin: 20px 0;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
}

.ParentRapper-Privacy li {
    margin-left: 30px;
    margin-bottom: 10px;
}

.ParentRapper-Privacy .PrivacyLink {
    font-size: 17px;
    color: rgb(226, 226, 226);
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.md-30 {
    margin-bottom: 30px !important;
}


.md-40 {
    margin-bottom: 40px !important;
}

@media(max-width:500px) {
    .ParentRapper-Privacy .PrivacyLink {
        font-size: 13px;
    }

    .ParentRapper-Privacy{
        width: 90%;
    }
}