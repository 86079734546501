.contact-page {
    background: var(--dark-back);
    height: fit-content;
    padding: 2.5rem 0;
}

.contact-page .contact-body {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.contact-page .contact-left {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    color: #fff;
}



.contact-page .contact-body h1 {
    font-family: 'Inter', sans-serif;
    font-size: 76px;
    font-style: normal;
    font-weight: 400;
    line-height: 92px;
    width: 70%;
    font-size: 55px;

}

.contact-page .interested-items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}

.contact-body .contact-interested {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.contact-page .contact-interested h4 {
    font-weight: 600;
    font-size: 20px;
}

.contact-page .interested-upper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
    gap: 1rem;
}

.contact-page .interested-lower-sec {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
    gap: 1rem;
}

.contact-page .interested-btn-upper {
    appearance: none;
    color: aliceblue;
    width: 26%;
    position: relative;
    height: 3.3rem;
    cursor: pointer;

}

.customInputButton {
    background-color: white;
    appearance: none;
    position: relative;
    color: black;
    transition: 0.3s;
}

.customInputButton:hover::after {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: 0.5s;
}

.customInputButton:hover::before {
    color: white;
}

.customInputButton::after {
    content: "";
    position: absolute;
    width: 0%;
    height: 100%;
    background-color: #2251FF;
    z-index: 2;
    transition: 0.5s;
}

.customInputButton:checked::after {
    width: 100%;
    transition: 0.5s;
}

.customInputButton::before {
    content: attr(value);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: black;
    text-align: center;
    font-size: 14px;
    z-index: 5;
}

.customInputButton:checked::before {
    color: white;
}

.customInputButton:checked::after {
    color: white;
}


.contact-page .interested-btn-lower {
    appearance: none;
    color: aliceblue;
    width: 70%;
    position: relative;
    height: 3.3rem;
    cursor: pointer;
}

/* contact form style  */
.contact-page .form-heading {
    font-weight: normal;
    font-family: Inter;
    font-size: 35px;
}

.contact-page .contact-form {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

/* form input */
.contact-page .contact-input {
    height: 3.3rem;
    border: 1.5px solid #fff;
    font-size: 16px;
    padding: 10px;
    background-color: transparent;
    color: #fff;
}

.contact-page .contact-input::placeholder {
    color: rgba(255, 255, 255, 0.937);
    font-family: "Inter", sans-serif;
}

.contact-page .contact-input:focus {
    outline: 0.5px solid var(--dark-text2);

}

.contact-page .contact-form form {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: auto;
    gap: 1rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.contact-page .name-input-sec {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
}

.contact-page .name-input-sec input {
    width: 50%;
}

.contact-page .phoneCompany {
    display: flex;
    flex-direction: revert;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.contact-page .phoneCompany input {
    width: 50%;
}

.contact-page #descripton {
    background: transparent;
    padding: 10px;
    border: 1.5px solid #fff;
    color: #fff;
}

.contact-page #descripton::placeholder {
    color: rgba(255, 255, 255, 0.937) !important;
    width: 100%;
    font-family: "Inter", sans-serif;

}

textarea {
    resize: none;
}

@media (min-width:1536px) {
    .contact-page .interested-upper button {
        font-size: 14px;
    }

    .contact-page .interested-lower-sec button {
        font-size: 14px;
    }
}

.contact-body .budget-lists {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.contact-body .budget-fix-btn {
    cursor: pointer;
    background-color: white;
    width: 20%;
    height: 3rem;
    outline: none;
}

.contact-body .project-details-heading {
    font-weight: normal;
    font-family: Inter;
    font-size: 35px;

}

.contact-body .project-details {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.contact-body .contact-submit {
    width: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.contact-submit button {
    width: 9rem;
    height: 3.5rem;
    cursor: pointer;
    color: var(--dark-text);
    border: none;
    outline: none;
    font-size: 16px;
    position: relative;
    overflow: hidden;

}

.contact-submit button::after {
    content: "Submit";
    background-color: #2251FF;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 3;
    transition: 0.5s;
}

.contact-submit button:hover::after {
    height: 0;
    transition: 0.5s;
}

.contact-submit button::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    width: 100%;

    border: 1px solid #dddcdc;
    transition: 0.5s;
    z-index: 5;
}

.contact-submit button:hover::before {
    height: 100%;
    transition: 0.5s;
}

.contact-submit button:hover {
    /* background-color: transparent; */
    color: black;
    transition: 0.5s;
    /* box-shadow: -5px 6px 26px -3px #d3d1d1; */
}

/*
====================================================
 contact page right section 
=====================================================
 */
.contact-page .contact-right {
    width: 30%;
    height: 40rem;
    color: #fff;
}

.contact-page .contact-right h3 {
    display: flex;
    flex-direction: revert;
    align-items: center;
    justify-content: flex-start;
    gap: 3px;
}

.contact-body .extra-service-card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: auto;
    margin-bottom: 1rem;
    gap: 5px;
    padding: 10px;
}


.contact-body .service-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    flex: 1;
    gap: 5px;
}

.contact-body .service-content * {
    font-family: 'Inter', sans-serif;
}

.contact-body .service-content p,
a {
    font-size: 12px;
}

.contact-body .service-content a {
    /* font-size: 12px; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #2251FF;
}

.contact-body .service-content img {
    height: 1rem;
    width: 1rem;
}


.contact-body .service-cards-left {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    /* width: fit-content; */
    width: 1.5rem;
    height: 1.5rem;
    gap: 1rem;
}

.contact-body .service-cards-left img {
    height: 100%;
    width: 100%;
}

.contact-body .contact-links {
    text-decoration: none;
    color: #fff !important;
}

.contact-body .service-cards-left img {
    filter: invert(1);
}

[type=submit] {
    cursor: pointer;
}

/*
=====================================
Modal dark Mode
=====================================
*/

.mantine-y43iiw {
    background-color: rgb(14 14 14 / 21%);
}

.mantine-90zg6 {
    background: var(--dark-back);
}

.mantine-19pz3dh {
    background: var(--dark-back);
}

.mantine-gszoqu,
.mantine-kcpopt,
.mantine-1ix1d88 {
    background: transparent;
    color: #fff;
}

.mantine-kcpopt option {
    background: black;
}


/* ==============================>
Responsive
=============================> */


@media (max-width:888px) {
    .contact-page .contact-body h1 {
        line-height: normal;
        font-size: 43px;
    }


    .customInputButton::before {
        font-size: 12px;
    }

    .contact-submit button {
        width: 7rem;
        height: 3rem;
    }

    .contact-page .contact-body {
        width: 100%;
        flex-direction: column;
        gap: 7rem;
    }



    .extra-service {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }


    .contact-page .contact-right {
        height: fit-content;
    }

    .contact-page .contact-left {
        width: 100%;
        margin: auto;
    }

    .contact-page .interested-upper,
    .contact-page .contact-form form,
    .contact-page .interested-lower-sec,
    .contact-page .contact-right {
        width: 100%;
    }



    .contact-page .interested-btn-upper,
    .contact-page .interested-btn-lower {
        height: 3rem;
    }

    .contact-right {
        width: 100% !important;
        margin: auto;
    }

    .contact-body .extra-service-card {
        padding: 10px 0;
    }




}

@media (max-width:450px) {
    .contact-page .contact-body h1 {
        font-size: 34px;
    }

    .contact-body .project-details-heading,
    .contact-page .form-heading {
        font-size: 24px;
    }

    .extra-service {
        justify-content: flex-start;
    }

    .contact-right {
        width: 100% !important;
        margin: auto;
    }

    .customInputButton:hover::after {
        width: 0%;
    }

    .customInputButton:checked::after {
        width: 100%;
    }

    .customInputButton::before {
        color: black;
        font-size: 9px;
    }

    .customInputButton:hover::before {
        color: black;
    }

    .contact-page .interested-upper {
        gap: 0.3rem;
    }

    .contact-page .interested-lower-sec {
        gap: 0.3rem;
    }

    .contact-body .budget-lists {
        gap: 0.3rem;
    }

    .customInputButton:checked::before {
        color: white;
        font-size: 10px;
        word-wrap: break-word;
    }

    .customInputButton:checked::after {
        color: white;
    }
}

@media (max-width:300px) {
    .customInputButton::before {
        font-size: 9px;
    }

    .contact-page .interested-btn-upper {
        height: 2.5rem;
    }

    .contact-page .interested-btn-lower {
        height: 2.5rem;
    }

    .customInputButton::before {
        font-size: 9px;
    }

    .contact-page .contact-input,
    .contact-body .budget-fix-btn {
        height: 2.5rem;
    }

    .contact-page .contact-input::placeholder {
        font-size: 9px;
    }

    .contact-page #descripton {
        height: 6rem;
    }
}